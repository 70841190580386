declare global {
    interface Window {
        __INITIAL_ENV__: Record<string, string>;
    }
}

const ENV = process.env;

const throwEnvExistenceError = (env: string) => {
    throw new Error(`env: ${env} is not properly defined`);
};

// NODE_ENV - defines build environment (production or development - may be extended)
export const isProduction: boolean = ENV.NODE_ENV === "production";
export const isDevelopment: boolean = ENV.NODE_ENV === "development";

if (!isProduction && !isDevelopment) {
    throwEnvExistenceError("NODE_ENV");
}

// EXEC_ENV - defines execution environment (browser or server)
export const isBrowser: boolean = ENV.EXEC_ENV === "browser";
export const isServer: boolean = ENV.EXEC_ENV === "server";

if (!isBrowser && !isServer) {
    throwEnvExistenceError("EXEC_ENV");
}

const UNIVERSAL_ENV = isBrowser ? window.__INITIAL_ENV__ : ENV;

// BUILD_IMAGE_TAG - image tag - provided by dockerfile
export const buildImageTag: string | undefined = UNIVERSAL_ENV.BUILD_IMAGE_TAG;
// BUGSNAG_RELEASE_STAGE — define release stage, where only "production" and "staging" are reported
export const bugsnagReleaseStage = (UNIVERSAL_ENV.BUGSNAG_RELEASE_STAGE ?? "development") as "staging" | "production" | "development";
// RP_REDIRECT_URL - redirect URL to RP app
export const rpRedirectUrl: string | undefined = UNIVERSAL_ENV.RP_REDIRECT_URL;

if (!rpRedirectUrl) {
    throwEnvExistenceError("RP_REDIRECT_URL");
}

// GH_REDIRECT_URL - redirect URL to GH app
export const ghRedirectUrl: string | undefined = UNIVERSAL_ENV.GH_REDIRECT_URL;

if (!ghRedirectUrl) {
    throwEnvExistenceError("GH_REDIRECT_URL");
}

// FACEBOOK_APP_ID - define appId for Facebook auth
export const facebookAppId: string | undefined = UNIVERSAL_ENV.FACEBOOK_APP_ID;
// USER_COM_ID - define user.com ID. Scripts should be initialized only if this is defined.
export const userComId: string | undefined = UNIVERSAL_ENV.USER_COM_ID;
// USER_COM_APP_SUBDOMAIN - define user.com instance subdomain
export const userComAppSubdomain: string | undefined = UNIVERSAL_ENV.USER_COM_APP_SUBDOMAIN;
// GTM_ID - defines Google Tag Manager ID for production and staging environment
export const gtmId: string | undefined = UNIVERSAL_ENV.GTM_ID;
// ALGOLYTICS_ID - define algolytics ID used to report events to big data. When null algolytics won't be used
export const algolyticsId: string | undefined = UNIVERSAL_ENV.ALGOLYTICS_ID;
// SHOW_ALGOLYTICS_LOGS - enable logs for Algolytics events - not for use in production
export const showAlgolyticsLogs: boolean = UNIVERSAL_ENV.SHOW_ALGOLYTICS_LOGS === "true";
//OpenStreetMap
export const osmTileUrl: string | null = UNIVERSAL_ENV.OSM_TILE_URL ?? null;
if (isServer && osmTileUrl === null) {
    throw new Error("env: OSM_TILE_URL is not defined for server environment");
}
// KM_API_URL - define KM API URL for absolute paths in expressjs
export const kmApiUrl: string | undefined = UNIVERSAL_ENV.KM_API_URL;

export const BROWSER_ENV = {
    BUGSNAG_RELEASE_STAGE: bugsnagReleaseStage,
    GTM_ID: gtmId,
    GH_REDIRECT_URL: ghRedirectUrl,
    RP_REDIRECT_URL: rpRedirectUrl,
    FACEBOOK_APP_ID: facebookAppId,
    USER_COM_ID: userComId,
    USER_COM_APP_SUBDOMAIN: userComAppSubdomain,
    ALGOLYTICS_ID: algolyticsId,
    SHOW_ALGOLYTICS_LOGS: UNIVERSAL_ENV.SHOW_ALGOLYTICS_LOGS,
    OSM_TILE_URL: osmTileUrl
};
